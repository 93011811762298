import React from "react";
import "./styles.css";

import logo_topo from "../../assets/logo_topo.png";

const Menu = () => {
  return (
    <nav id="menu">
      <section className="container">
        <figure className="logo">
          <img src={logo_topo} alt="Logo" />
        </figure>
      </section>
    </nav>
  );
};

export default Menu;
