import "./styles.css";

import CTAForm from "../../components/CTAForm";

import content1_1 from "../../assets/content1_1.png";
import content1_2 from "../../assets/content1_2.png";
import content1_3 from "../../assets/content1_3.png";
import content1_4 from "../../assets/content1_4.png";
import content1_5 from "../../assets/content1_5.png";

const Content = () => {
  return (
    <section id="content">
      <article className="content1">
        <div className="container">
          <h2 className="title orange">Demais Serviços</h2>

          <div className="line"></div>

          <div className="gallery">
            <div className="item">
              <div className="top">
                <figure className="left">
                  <img src={content1_1} alt="Ícone" />
                </figure>

                <p className="item_title">Calculo de inicial</p>
              </div>

              <p className="item_text">
                O levantamento do passivo trabalhista, na atual conjuntura de
                desenvolvimento nacional se faz necessária para as empresas que
                planejam desenvolvimento sólido.
              </p>
            </div>

            <div className="item">
              <div className="top">
                <figure className="left">
                  <img src={content1_2} alt="Ícone" />
                </figure>

                <p className="item_title">Impugnação </p>
              </div>

              <p className="item_text">
                Com sugestão de argumentação e jurisprudência. É um ato
                processual que se realiza por meio de petição na qual se procura
                anular ou desfazer um ato processual considerado injusto.
              </p>
            </div>

            <div className="item">
              <div className="top">
                <figure className="left">
                  <img src={content1_3} alt="Ícone" />
                </figure>

                <p className="item_title">Elaboração de laudo pericial</p>
              </div>

              <p className="item_text">
                O Judiciário recorre ao perito contábil quando o juiz necessita
                de um laudo profissional especializado ou para atender ao pedido
                de uma das partes envolvidas no processo.
              </p>
            </div>
          </div>

          <div className="gallery second">
            <div className="item">
              <div className="top">
                <figure className="left">
                  <img src={content1_4} alt="Ícone" />
                </figure>

                <p className="item_title">
                  Cálculos conforme fase do processo sentença e acordão
                </p>
              </div>

              <p className="item_text">
                A decisão do órgão colegiado de um tribunal, que se diferencia
                da sentença, da decisão interlocutório e do despacho, que emanam
                de um órgão monocrático.
              </p>
            </div>

            <div className="item">
              <div className="top">
                <figure className="left">
                  <img src={content1_5} alt="Ícone" />
                </figure>

                <p className="item_title">Liquidação de verbas rescisórias</p>
              </div>

              <p className="item_text">
                As verbas rescisórias devidas em caso de dispensa sem justa
                causa são: o saldo de salário, o aviso-prévio, férias vencidas e
                proporcionais acrescidas de 1/3 e 13º salário salário.
              </p>
            </div>
          </div>

          <CTAForm />
        </div>
      </article>

      <article className="content2">
        <div className="container">
          <div className="left">
            <h2 className="title">Sobre a Uni Cálculos</h2>

            <div className="line"></div>

            <p className="text">
              Temos uma relação de parceria com nossos clientes, sempre
              atualizados, prestamos um pronto atendimento esclarecendo de forma
              assertiva as dúvidas de nossos clientes, toda essa estrutura de
              atendimento foi desenvolvida ao longo da trajetória de nossa
              empresa.
            </p>

            <p className="text">
              Traçar estratégias, sempre buscando a melhor alternativa para os
              objetivos dos clientes é nossa característica primordial, o que
              possibilita a obtenção de soluções além do convencional, atendendo
              assim as expectativas, as metas e os resultados almejados.
            </p>

            <CTAForm />
          </div>

          <div className="right">
            <p className="name">Edilson Santos de Souza</p>

            <div className="line"></div>

            <ul>
              <li>
                <b>Graduado em Ciências Contábeis</b> pela Universidade Nove de
                Julho;
              </li>
              <li>
                <b>Pós-Graduação em Perícia Contábil</b> pela TREVISAN.
              </li>
            </ul>

            <div className="line full"></div>

            <p className="name">Rafael Soares da Fonseca</p>

            <div className="line"></div>

            <ul>
              <li>
                <b>Graduado em Ciências Contábeis</b> pela Universidade Camilo
                Castelo Branco;
              </li>
              <li>
                <b>Pós-Graduação em Perícia Contábil</b> pela TREVISAN.
              </li>
            </ul>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Content;
