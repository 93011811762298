import { useEffect, useState } from "react";
import "./styles.css";

const CTAForm = (props) => {
  const [text, setText] = useState("SOLICITE UM ORÇAMENTO");

  useEffect(() => {
    if (props.text !== undefined) setText(props.text);
  }, [props.text]);

  return (
    <button id="ctaForm">
      <a href="#form">
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CTAForm;
