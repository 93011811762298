import "./styles.css";

import Form from "../../components/Form";

import content2 from "../../assets/content2.png";
import content2Mobile from "../../assets/mobile/content2.png";

const FormPage = () => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  return (
    <section id="formPage">
      <div className="formPage1">
        <div className="container">
          <figure className="final">
            <img src={isMobile ? content2Mobile : content2} alt="Imagem" />
          </figure>
        </div>
      </div>

      <div className="formPage2">
        <article className="container">
          <h2 className="title">Solicite agora mesmo um orçamento!</h2>

          <div className="line"></div>

          <p className="text">
            Preencha o formulário, nossa equipe de atendimento irá entrar em
            contato para <br />
            <b>tirar suas dúvidas e ajudar no que estiver ao nosso alcance.</b>
          </p>

          <Form />
        </article>
      </div>
    </section>
  );
};

export default FormPage;
