import "./styles.css";

import CTAForm from "../../components/CTAForm";

import about1 from "../../assets/about1.png";
import about2 from "../../assets/about2.png";
import about3 from "../../assets/about3.png";
import about4 from "../../assets/about4.png";
import about1Mobile from "../../assets/mobile/about1.png";
import about2Mobile from "../../assets/mobile/about2.png";
import about3Mobile from "../../assets/mobile/about3.png";
import about4Mobile from "../../assets/mobile/about4.png";

const About = () => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  return (
    <section id="about">
      <article className="about1" id="about1">
        <div className="container vertical">
          <h2 className="title">Prestação de Contas Condominial</h2>

          <p className="subtitle orange">
            Perícia Judicial e Extrajudicial para fiscalização da Administração
            do SÍndico
          </p>

          <div className="gallery">
            <div className="left">
              <b>
                <p className="text">
                  Nosso serviço de Prestação de Contas Condominial, inclui:
                </p>
              </b>

              <ul>
                <li>
                  Análise dos lançamentos de receitas e despesas das pastas de
                  prestação de contas por cada conta individual, fiscalizando se
                  houve apresentação dos documentos contábeis “justificando” o
                  pagamento como: (notas fiscais; RPA; orçamentos da prestação
                  do serviço; tipo de serviço realizado no condomínio; recibos
                  detalhados dos serviços prestados; comprovantes de pagamentos
                  e outros documentos contábeis);
                </li>
                <li>Análise das atas de assembleia;</li>
                <li>
                  Análise mensal, semestral ou anual, referente às pastas de
                  prestação de contas para emissão do relatório de fiscalização.
                </li>
              </ul>

              <CTAForm />
            </div>

            <figure className="right">
              <img
                src={isMobile ? about1Mobile : about1}
                alt="Homems apertando a mão"
              />
            </figure>
          </div>
        </div>
      </article>

      <article className="about2" id="about2">
        <div className="container">
          <h2 className="title">Cálculos Trabalhistas</h2>

          <p className="subtitle orange">NOSSOS SERVIÇOS</p>

          <div className="gallery">
            <figure className="left">
              <img
                src={isMobile ? about2Mobile : about2}
                alt="Homem realizando cálculos"
              />
            </figure>

            <div className="right">
              <b>
                <p className="text">
                  Auxiliamos em Laudo Pericial e Pareceres:
                </p>
              </b>

              <ul>
                <li>Liquidação de inicial;</li>
                <li>Apontamento de horas extras;</li>
                <li>
                  Analise dos cálculos das partes com subsidio para impugnação;
                </li>
                <li>Cálculo para a fase da execução sentença/acórdão.</li>
                <li>
                  <b>Impugnação:</b> Parecer explicativo dos pontos incorretos
                  na apuração da parte contraria;
                </li>
                <li>
                  <b>Acordo:</b> Discriminação de verbas salariais e
                  indenizatória para apuração das contribuições previdenciárias.
                </li>
                <li>
                  <b>Saldo remanescente:</b>
                  Atualização dos valores pagos no processo;
                </li>
                <li>
                  <b>Atuamos como assistente técnico:</b>
                  De ambas às partes de forma imparcial, garantido as condições
                  técnicas envolvidas no processo;
                </li>
                <li>Elaboração de quesitos;</li>
              </ul>

              <b>
                <p className="text">
                  Tudo através do sistema da Justiça{" "}
                  <span className="orange">Pje-Calc.</span>
                </p>
              </b>

              <CTAForm />
            </div>
          </div>
        </div>
      </article>

      <article className="about3" id="about3">
        <div className="container">
          <h2 className="title">Cálculos Previdenciários</h2>

          <p className="subtitle orange">
            ANÁLISE COM SOLUÇÕES PRECISAS E COMPLETAS
          </p>

          <div className="gallery">
            <div className="left">
              <b>
                <p className="text">Nosso Cálculo Previdenciário, inclui:</p>
              </b>

              <ul>
                <li>R.M.I, tempo de contribuição e planejamento futuro.</li>
                <li>Tempo de contribuição;</li>
                <li>Melhor benefício em um período;</li>
                <li>Contribuição em atraso;</li>
                <li>Restituição de contribuição pagas acima do teto.</li>
              </ul>

              <CTAForm />
            </div>

            <figure className="right">
              <img
                src={isMobile ? about3Mobile : about3}
                alt="Pessoas conversando"
              />
            </figure>
          </div>
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <h2 className="title">Perícia Judicial e Extrajudicial</h2>

          <p className="subtitle orange">
            ETAPAS DA PERÍCIA JUDICIAL E FRENTE DE ATUAÇÃO
          </p>

          <div className="gallery">
            <figure className="left">
              <img
                src={isMobile ? about4Mobile : about4}
                alt="Mulher segurando a balança da justiça nas mãos"
              />
            </figure>

            <div className="right">
              <b>
                <p className="text">
                  A assistência técnica na perícia judicial é abrangente e
                  envolve algumas etapas:
                </p>
              </b>

              <ul>
                <li>
                  <b>1 . Ajuizamento de ação:</b> <br />
                  1.1 . Elaboração de parecer técnico para embasamento de
                  petição inicial.
                </li>
                <li>
                  <b>2 . Contestação/Impugnação:</b> <br />
                  2.1 . Elaboração de parecer técnico para embasamento da
                  contestação.
                </li>
                <li>
                  <b>3 . Perícia Judicial:</b> <br />
                  3.1 . Elaboração de quesitos. <br />
                  3.2 . Atendimento ao Termo de Diligência efetuado pelo Perito
                  Judicial. <br />
                  3.3 . Elaboração do Parecer Técnico Contábil, discordando ou
                  concordando com o Laudo Pericial Contábil.
                </li>
                <li>
                  <b>4 . Outras frentes de atuação da assistência técnica:</b>
                  <br />
                  4.1 . Prestação de serviços de análise e elaboração de
                  cálculos de prestação de contas; <br />
                  4.2 . Ajuizamento; <br />
                  4.3 . Impugnação; <br />
                  4.4 . Cumprimento de Sentença.
                </li>
              </ul>

              <CTAForm />
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default About;
