import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";

import CTAForm from "../CTAForm";

const Carousel = ({ arr, isVideo, slidesToShow }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  const ids = ["#header3", "#about1", "#about2", "#about3"];

  return (
    <article id="carousel">
      <div className="container">
        <Slider {...settings}>
          {arr.map((element, index) => (
            <div key={index}>
              {isVideo ? (
                <video controls className="item">
                  <source src={element.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <a
                  href={`${index > 0 ? ids[index - 1] : "#"}`}
                  className={`item ${
                    index === 0 ? "first-item" : "not-first-item"
                  }`}
                  style={{
                    backgroundImage: `url("${element.image}")`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                >
                  <h1
                    className="title"
                    dangerouslySetInnerHTML={{ __html: element.title }}
                  ></h1>

                  {element.subtitle && (
                    <p
                      className="subtitle"
                      dangerouslySetInnerHTML={{ __html: element.subtitle }}
                    ></p>
                  )}

                  <CTAForm text={"Saiba Mais"} />
                </a>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </article>
  );
};

export default Carousel;
